import AmeyPhil, { ChimeType } from './AmeyPhil'
import DelayCodeMapping from './DarwinDelayCodes_Female1.json'
import NamedServices from './named-services.json'

import type { CustomAnnouncementButton } from '@announcement-data/AnnouncementSystem'

type Writeable<T> = { -readonly [P in keyof T]: T[P] }

export default class AmeyCelia extends AmeyPhil {
  readonly NAME = 'Amey/Ditra - Celia Drummond'
  readonly ID = 'AMEY_CELIA_V1'
  readonly FILE_PREFIX = 'station/ketech/celia'
  readonly SYSTEM_TYPE = 'station'

  protected readonly BEFORE_TOC_DELAY: number = 30
  protected readonly BEFORE_SECTION_DELAY: number = 550
  protected readonly SHORT_DELAY: number = 500

  readonly DelayCodeMapping = DelayCodeMapping

  protected readonly genericOptions = {
    platform: 's.platform-2',
    platformZeroM: 'm.0',
    platformZeroE: 'e.0',
  }

  protected readonly callingPointsOptions = {
    beforeCallingAtDelay: 650,
    afterCallingAtDelay: 0,
    betweenStopsDelay: 220,
    aroundAndDelay: 0,
    rrbTerminateAudio: 'e.where the train will terminate due to engineering work-2',
  }

  protected readonly standingOptions = {
    thisIsId: 's.this is-2',
    nowStandingAtId: 's.the train now standing at platform',
  }

  protected readonly shortPlatformOptions = {
    unknownLocation: 'w.please listen for further on train announcements',
  }

  get DEFAULT_CHIME(): ChimeType {
    return 'three'
  }

  get PLATFORMS() {
    return ['0'].concat(
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        .flatMap(x => [`${x}`, `${x}a`, `${x}b`, `${x}c`, `${x}d`])
        .concat(['13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']),
    )
  }

  protected get AVAILABLE_TOCS() {
    return {
      withServiceToFrom: [
        'additional Chiltern Railways',
        'Alphaline',
        'Anglia Railways',
        'Arriva Trains Northern',
        'Arriva Trains Wales',
        'c2c',
        'Central Trains',
        'Charter',
        'Chiltern Line',
        'Chiltern Railways',
        'Connex',
        'Connex Express',
        'Connex Metro',
        'Connex Racecourse Special',
        'Connex Rail',
        'Connex South Central',
        'Connex South Eastern',
        'Country',
        'CrossCountry',
        'diverted',
        'East Midlands Railway',
        'East Midlands Trains',
        'express',
        'First Capital Connect',
        'First Great Eastern',
        'First Great Western',
        'First Great Western Adelante',
        'First Great Western Atlantic Coast Express',
        'First Great Western Bristolian',
        'First Great Western Cathedrals Express',
        'First Great Western Cheltenham Flier',
        'First Great Western Cheltenham Spa Express',
        'First Great Western Cornish Riviera',
        'First Great Western Devon Belle',
        'First Great Western Golden Hind',
        'First Great Western Hibernian',
        'First Great Western High Speed',
        'First Great Western Intercity',
        'First Great Western Link',
        'First Great Western Mayflower',
        'First Great Western Merchant Venturer',
        'First Great Western Motorail',
        'First Great Western Night Riviera',
        'First Great Western Pembroke Coast Express',
        'First Great Western Red Dragon',
        'First Great Western Royal Duchy',
        'First Great Western Royal Wessex',
        'First Great Western St David',
        'First Great Western Torbay Express',
        'First Transpennine Express',
        'football special',
        'Gatwick Express',
        'GNER',
        'Grand Central',
        'Great Eastern',
        'Great North Eastern Railways',
        'Great North Eastern Railways White Rose',
        'Great North Eastern Railways Yorkshire Pullman',
        'Great Northern',
        'Great Western',
        'Great Western Railway',
        'Greater Anglia',
        'Heathrow Express',
        'Holidaymaker',
        'Holidaymaker Express',
        'Hull Trains',
        'London Midland',
        'London Midland City',
        'London Midland Express',
        'London North Eastern Railway',
        'London Northwestern Railway',
        'London Overground',
        'London Transport Buses',
        'London Underground',
        'Midland Mainline',
        'Midland Mainline High Speed Train',
        'Midland Mainline Turbostar',
        'Mystery Excursion',
        'National Express',
        'National Express East Coast',
        'New Southern Railway',
        'New Southern Railway Brighton Express',
        'Northern',
        'Northern Rail',
        'Northern Spirit',
        'Northern Trains',
        'One',
        'One Anglia',
        'Orient Express',
        'private charter train',
        'race special',
        'racecourse special',
        'ramblers special',
        'relief',
        'return charter train',
        'rugby special',
        'ScotRail',
        'ScotRail Railways',
        'Silverlink County',
        'Silverlink Metro',
        'South Central',
        'South West Trains',
        'South Western Railway',
        'Southeastern',
        'Southeastern Trains',
        'Southern',
        'Southern Railway',
        'Southern Railway Brighton Express',
        'special charter',
        'Stansted Express',
        'steam charter train',
        'steam hauled special',
        'stopping',
        'Tarka Line',
        'Thames Trains',
        'Thameslink',
        'Thameslink City Flier',
        'Thameslink City Metro',
        'The Mid Hants Steam Railway',
        // 'The Swanage Railway',
        'The Yorkshire Pullman',
        'Tramlink',
        'Transpennine Express',
        'Tyne and Wear Metro',
        'Valley Lines',
        'Virgin Pendolino',
        'Virgin Trains',
        'Virgin Trains Armada',
        'Virgin Trains Cornish Scot',
        'Virgin Trains Cornishman',
        'Virgin Trains Cross Country',
        'Virgin Trains Devon Scot',
        'Virgin Trains Devonian',
        'Virgin Trains Dorset Scot',
        'Virgin Trains Midland Scot',
        'Virgin Trains Pines Express',
        'Virgin Trains Sussex Scot',
        'Virgin Trains Wessex Scot',
        'Virgin Voyager',
        // https://en.wikipedia.org/wiki/Venice-Simplon_Orient_Express
        'VSOE Pullman',
        'WAGN',
        'Wales and Borders',
        'Wales and West',
        'Wales and West Alphaline',
        'Wales and West Weymouth Sand and Cycle Explorer',
        'West Anglia',
        'West Anglia Great Northern Railways',
        'West Midlands Railway',
        'West Midlands Trains',
        'White Rose',
        'Yorkshire Pullman',
      ],
      standaloneOnly: [
        'additional',
        'additional football special',
        'Arriva Trains Merseyside',
        'Arriva Trains',
        'Arriva Transpennine',
        'Avanti West Coast',
        'c2c Rail',
        'Cardiff Railways',
        'Channel Tunnel Rail Link',
        'Chiltern Railway Company',
        'Eurostar',
        'Govia',
        'Great North Eastern Railway',
        'Great Western Railway Atlantic Coast Express',
        'Great Western Railway Bristolian',
        'Great Western Railway Cathedrals Express',
        'Great Western Railway Cheltenham Flier',
        'Great Western Railway Cheltenham Spa Express',
        'Great Western Railway Cornish Riviera',
        'Great Western Railway Devon Belle',
        'Great Western Railway Devon Express',
        'Great Western Railway Golden Hind',
        'Great Western Railway Hibernian',
        'Great Western Railway High Speed',
        'Great Western Railway Intercity',
        'Great Western Railway Mayflower',
        'Great Western Railway Merchant Venturer',
        'Great Western Railway Night Riviera',
        'Great Western Railway Pembroke Coast Express',
        'Great Western Railway Red Dragon',
        'Great Western Railway Royal Duchy',
        'Great Western Railway Royal Wessex',
        'Great Western Railway St David',
        'Great Western Railway Torbay Express',
        'Island Line',
        'LTS Rail',
        'Merseyside Electrics',
        'Network Southeast',
        'North London Railways',
        'South Central Trains',
        'steam special',
        'Transport for Wales',
        'Wessex',
        'Virgin Trains the Sussex Scot',
        'West Anglia Great Northern Railway',
        'West Coast Railway Company',
      ],
    }
  }

  protected get DISRUPTION_REASONS() {
    return [
      'a broken down freight train',
      'a broken down preceding train',
      'a broken down train',
      'a broken rail',
      'a cable fire',
      'a chemical spillage',
      'a currently unidentified reason which is under investigation',
      'a customer having been taken ill on a preceding train',
      'a customer having been taken ill on this train',
      'a dangerous gas leak',
      'a derailment',
      'a driver shortage',
      'a failed train',
      'a failure of level crossing apparatus',
      'a failure of signalling equipment',
      'a fallen tree on the line',
      'a fatality',
      'a fault on a level crossing',
      'a fault on a preceding that has now been rectified',
      'a fault on a preceding train',
      'a fault on the train',
      'a fault on the train that has now been rectified',
      'a fault on this train which cannot be rectified',
      'a fault on this train which is being attended to',
      'a fault on trackside equipment',
      'a fault that has occurred whilst attaching coaches to this train',
      'a fault that has occurred whilst detaching coaches from this train',
      'a fault with the door mechanism on board a preceding train',
      'a fault with the door mechanism on board this train',
      'a fire',
      'a gas leak in the area',
      'a lack of suitable carriages',
      'a landslide',
      'a landslip',
      'a late-running preceding service',
      'a lightning strike',
      'a lightning strike affecting the signalling equipment',
      'a lineside fire',
      'a major electrical power fault',
      'a mechanical fault on a level crossing',
      'a member of staff providing assistance to a passenger',
      'a passenger having been taken ill on a preceding train',
      'a passenger incident',
      'a passenger requiring urgent attention',
      'a points failure',
      'a power failure',
      'a problem on property adjacent to the railway',
      'a report of an injury to a person on the track',
      'a road vehicle damaging a level crossing',
      'a road vehicle on the line',
      'a road vehicle striking a railway bridge',
      'a security alert',
      'a shortage of available coaches',
      'a shortage of serviceable trains',
      'a shortage of train dispatch staff',
      'a slow-running preceding freight train running behind schedule',
      'a slow-running preceding train with a technical fault',
      'a staff shortage',
      'a suspected fatality',
      'a technical fault on the service',
      'a technical fault to lineside equipment',
      'a temporary fault with the signalling equipment',
      'a temporary shortage of drivers',
      'a temporary shortage of train crews',
      'a temporary speed restriction because of signalling equipment repairs',
      'a temporary speed restriction because of track repairs',
      'a ticket irregularity on board a preceding train',
      'a ticket irregularity on board this train',
      'a track circuit failure',
      'a train failure',
      'a train speed restriction caused by a technical fault on this train',
      'additional cleaning duties',
      'additional maintenance requirements at the depot',
      'additional safety duties being carried out on board this train',
      'additional train movements to remove a broken down train',
      'adverse weather conditions',
      'after having been held waiting a late running connection',
      'ambulance attending an incident on the train',
      'ambulance attending an incident on this train',
      'an accident on a level crossing',
      'an accident to a member of the public',
      'an act of vandalism on this train',
      'an earlier act of vandalism on this train',
      'an earlier blockage of the line',
      'an earlier broken down train',
      'an earlier broken down train causing congestion',
      'an earlier electrical power supply problem',
      'an earlier fallen tree',
      'an earlier fallen tree on the line',
      'an earlier fatality',
      'an earlier fault on a level crossing',
      'an earlier fault that occurred whilst attaching coaches to this train',
      'an earlier fault that occurred whilst detaching coaches from this train',
      'an earlier fault with the door mechanism on board a preceding train',
      'an earlier fault with the door mechanism on board this train',
      'an earlier fault with the signalling equipment',
      'an earlier landslide',
      'an earlier lineside fire',
      'an earlier road vehicle striking a railway bridge',
      'an earlier security alert',
      'an earlier trespassing incident',
      'an earlier trespassing incident causing congestion',
      'an electrical power supply problem',
      'an external cause beyond our control',
      'an incident on the line',
      'an injury to a person on the track',
      'an obstruction on the line',
      'animals on the railway line',
      'animals on the track',
      'awaiting a connecting service',
      'awaiting a member of the train crew',
      'awaiting a portion of the train',
      'awaiting a replacement driver',
      'awaiting an available platform because of service congestion',
      'awaiting replacement coaches',
      'awaiting signal clearance',
      'bad weather conditions',
      'being held awaiting a late running connection',
      'being held awaiting a replacement bus connection',
      'caused by servicing problems in the depot',
      'children playing near the line',
      'christmas holidays',
      'coaches being detached from this train',
      'conductor rail problems',
      'confusion caused by a fault with the station information board',
      'congestion',
      'congestion caused by a failed train',
      'damaged track',
      'debris blown down on the line',
      'debris blown onto the railway line',
      'debris on the line',
      'earlier emergency track repairs',
      'earlier engineering works',
      'earlier overrunning engineering work',
      'earlier reports of a disturbance on board this train',
      'earlier reports of animals on the line',
      'earlier reports of debris on the line',
      'earlier reports of trespassers on the line',
      'earlier vandalism',
      'electric conductor rail problems',
      'emergency engineering work',
      'emergency track repairs',
      'engineering work',
      'engineering works',
      'extreme weather conditions',
      'failure of a preceding train',
      'flooding',
      'flooding on the line',
      'fog',
      'heavy rain',
      'high winds',
      'industrial action',
      // 'large numbers of passengers alighting from the trains at',
      // 'large numbers of passengers joining the trains at',
      'no driver available',
      'objects being thrown onto the line',
      'objects on the line',
      'on the preceding train',
      'operational problems',
      'overcrowding',
      'overcrowding caused by the',
      'overcrowding caused by the short formation of this service today',
      'overcrowding on the train',
      'overhead electric line problems',
      'overhead line damage',
      'overhead line problems',
      'overrunning engineering work',
      'passenger illness',
      'police activity on the line',
      'police attending a disturbance on a preceding train',
      'police attending a disturbance on this train',
      'police attending an incident on the train',
      'police attending an incident on this train',
      'police persuing suspects on the line',
      'police persuing suspects on the railway line',
      'poor rail conditions',
      'poor rail conditions caused by frost',
      'poor rail conditions caused by leaf fall',
      'replacing emergency equipment on this train',
      'reports of a blockage on the line',
      'reports of a disturbance on board this train',
      'reports of animals on the line',
      'reports of debris on the line',
      'reports of trespass on the line',
      'revenue protection officers attending this train',
      'severe weather conditions',
      'short formation of this train',
      'signal testing',
      'signalling difficulties',
      'signalling equipment repairs',
      'sliding train door problems',
      'slippery rail conditions',
      'snow',
      'staff sickness',
      'suspected damage to a railway bridge',
      'suspected damage to a railway bridge by a road vehicle',
      'suspected terrorist threat',
      'the action of vandals',
      'the advice of the emergency services',
      'the emergency communication cord being activated',
      'the emergency communication cord being activated on this train',
      'the emergency communication cord being pulled on the service',
      'the emergency communication cord being pulled on the train',
      'the emergency cord being pulled on the service',
      'the extreme heat',
      'the fire brigade attending an incident on the train',
      'the fire brigade attending an incident on this train',
      'the late arrival of an incoming train',
      'the london fire brigade attending an incident on the train',
      'the london fire brigade attending an incident on this train',
      'the previous service being delayed',
      'the short formation of this train',
      'the train being diverted from its scheduled route',
      'the unfortunate action of vandals',
      'third rail problems',
      'this train making additional stops on its journey',
      'track repairs',
      'train being held awaiting an available platform',
      'train door problems',
      'trespass on the line',
      'trespassers on the line',
      'vandalism',
      'vandalism on a preceding train',
      'vandalism on the service',

      // 'awaiting a member of train crew',
      // 'who has been delayed by the earlier disruption',
      // 'who in turn has been delayed by the current disruption',
      // 'who is delayed on a late-running service',

      // 'following signal staff instructions',
      // 'to run at a reduced speed while inspecting the line',

      // "emergency services attending an incident adjacent",
      // "to the railway line",
      // "emergency services attending an incident adjacent to",
      // "the railway line",

      // "a temporary speed restriction",
    ]
  }

  get STATIONS() {
    return [
      'AAP',
      'AAT',
      'ABA',
      'ABC',
      'ABD',
      'ABE',
      'ABH',
      'ABW',
      'ABY',
      'ACB',
      'ACC',
      'ACG',
      'ACH',
      'ACK',
      'ACL',
      'ACN',
      'ACR',
      'ACT',
      'ACY',
      'ADC',
      'ADD',
      'ADK',
      'ADL',
      'ADM',
      'ADN',
      'ADR',
      'ADS',
      'ADV',
      'ADW',
      'AFK',
      'AFS',
      'AFV',
      'AGL',
      'AGS',
      'AGT',
      'AGV',
      'AHD',
      'AHN',
      'AHS',
      'AHT',
      'AHV',
      'AIG',
      'AIN',
      'AIR',
      'ALB',
      'ALD',
      'ALF',
      'ALK',
      'ALM',
      'ALN',
      'ALP',
      'ALR',
      'ALT',
      'ALV',
      'ALW',
      'ALX',
      'AMB',
      'AMF',
      'AML',
      'AMR',
      'AMT',
      'AMY',
      'ANC',
      'AND',
      'ANF',
      'ANG',
      'ANL',
      'ANN',
      'ANS',
      'ANZ',
      'AON',
      'APB',
      'APD',
      'APF',
      'APG',
      'APP',
      'APS',
      'ARB',
      'ARD',
      'ARG',
      'ARL',
      'ARM',
      'ARN',
      'ARR',
      'ART',
      'ARU',
      'ASB',
      'ASC',
      'ASF',
      'ASG',
      'ASH',
      'ASK',
      'ASN',
      'ASP',
      'ASS',
      'AST',
      'ASY',
      'ATB',
      'ATH',
      'ATL',
      'ATN',
      'ATT',
      'AUD',
      'AUG',
      'AUI',
      'AUK',
      'AUR',
      'AUW',
      'AVF',
      'AVM',
      'AVN',
      'AVY',
      'AWK',
      'AWM',
      'AWT',
      'AXM',
      'AXP',
      'AYH',
      'AYL',
      'AYP',
      'AYR',
      'AYS',
      'AYW',
      'BAA',
      'BAB',
      'BAC',
      'BAD',
      'BAG',
      'BAH',
      'BAI',
      'BAJ',
      'BAK',
      'BAL',
      'BAM',
      'BAN',
      'BAR',
      'BAS',
      'BAT',
      'BAU',
      'BAV',
      'BAW',
      'BAY',
      'BBG',
      'BBK',
      'BBL',
      'BBN',
      'BBS',
      'BBW',
      'BCC',
      'BCE',
      'BCF',
      'BCG',
      'BCH',
      'BCJ',
      'BCK',
      'BCN',
      'BCS',
      'BCU',
      'BCV',
      'BCY',
      'BDA',
      'BDB',
      'BDG',
      'BDH',
      'BDI',
      'BDK',
      'BDL',
      'BDM',
      'BDN',
      'BDQ',
      'BDT',
      'BDW',
      'BDY',
      'BEA',
      'BEB',
      'BEC',
      'BEE',
      'BEF',
      'BEG',
      'BEH',
      'BEL',
      'BEM',
      'BEN',
      'BES',
      'BET',
      'BEU',
      'BEV',
      'BEX',
      'BEY',
      'BFD',
      'BFE',
      'BFF',
      'BFN',
      'BFR',
      'BGA',
      'BGD',
      'BGE',
      'BGG',
      'BGI',
      'BGL',
      'BGM',
      'BGN',
      'BGS',
      'BHD',
      'BHG',
      'BHI',
      'BHK',
      'BHM',
      'BHO',
      'BHR',
      'BHS',
      'BIA',
      'BIC',
      'BID',
      'BIF',
      'BIG',
      'BIK',
      'BIL',
      'BIN',
      'BIO',
      'BIP',
      'BIS',
      'BIW',
      'BIY',
      'BKA',
      'BKC',
      'BKD',
      'BKG',
      'BKH',
      'BKJ',
      'BKL',
      'BKM',
      'BKN',
      'BKO',
      'BKP',
      'BKQ',
      'BKS',
      'BKT',
      'BKW',
      'BLA',
      'BLB',
      'BLD',
      'BLE',
      'BLG',
      'BLH',
      'BLK',
      'BLL',
      'BLM',
      'BLN',
      'BLO',
      'BLP',
      'BLT',
      'BLV',
      'BLW',
      'BLX',
      'BLY',
      'BMB',
      'BMC',
      'BMD',
      'BME',
      'BMF',
      'BMG',
      'BMH',
      'BML',
      'BMN',
      'BMO',
      'BMP',
      'BMR',
      'BMS',
      'BMT',
      'BMV',
      'BMY',
      'BNA',
      'BNC',
      'BND',
      'BNE',
      'BNF',
      'BNG',
      'BNH',
      'BNI',
      'BNL',
      'BNM',
      'BNP',
      'BNR',
      'BNS',
      'BNT',
      'BNV',
      'BNW',
      'BNY',
      'BOA',
      'BOC',
      'BOD',
      'BOE',
      'BOG',
      'BOH',
      'BOM',
      'BON',
      'BOP',
      'BOR',
      'BOT',
      'BPB',
      'BPK',
      'BPN',
      'BPS',
      'BPT',
      'BPW',
      'BRA',
      'BRC',
      'BRE',
      'BRF',
      'BRG',
      'BRH',
      'BRI',
      'BRK',
      'BRL',
      'BRM',
      'BRN',
      'BRO',
      'BRP',
      'BRR',
      'BRS',
      'BRT',
      'BRU',
      'BRV',
      'BRW',
      'BRX',
      'BRY',
      'BSB',
      'BSC',
      'BSD',
      'BSE',
      'BSH',
      'BSI',
      'BSJ',
      'BSK',
      'BSL',
      'BSM',
      'BSN',
      'BSO',
      'BSP',
      'BSR',
      'BSS',
      'BSW',
      'BSY',
      'BTB',
      'BTE',
      'BTF',
      'BTG',
      'BTH',
      'BTL',
      'BTN',
      'BTO',
      'BTR',
      'BTS',
      'BTT',
      'BTY',
      'BUB',
      'BUC',
      'BUE',
      'BUG',
      'BUH',
      'BUJ',
      'BUK',
      'BUL',
      'BUO',
      'BUS',
      'BUT',
      'BUU',
      'BUW',
      'BUX',
      'BUY',
      'BVD',
      'BWB',
      'BWD',
      'BWG',
      'BWK',
      'BWN',
      'BWO',
      'BWS',
      'BWT',
      'BXB',
      'BXD',
      'BXH',
      'BXW',
      'BXY',
      'BYA',
      'BYB',
      'BYC',
      'BYD',
      'BYE',
      'BYF',
      'BYI',
      'BYK',
      'BYM',
      'BYN',
      'BYS',
      'CAD',
      'CAG',
      'CAM',
      'CAN',
      'CAO',
      'CAR',
      'CAT',
      'CAU',
      'CAY',
      'CBB',
      'CBC',
      'CBE',
      'CBG',
      'CBH',
      'CBL',
      'CBN',
      'CBP',
      'CBR',
      'CBS',
      'CBW',
      'CBY',
      'CCC',
      'CCH',
      'CCT',
      'CDB',
      'CDD',
      'CDF',
      'CDI',
      'CDO',
      'CDQ',
      'CDR',
      'CDS',
      'CDT',
      'CDU',
      'CEA',
      'CED',
      'CEF',
      'CEH',
      'CEL',
      'CES',
      'CET',
      'CEY',
      'CFB',
      'CFC',
      'CFD',
      'CFF',
      'CFH',
      'CFL',
      'CFN',
      'CFO',
      'CFR',
      'CFT',
      'CGD',
      'CGN',
      'CGW',
      'CHC',
      'CHD',
      'CHE',
      'CHF',
      'CHG',
      'CHH',
      'CHI',
      'CHK',
      'CHL',
      'CHM',
      'CHN',
      'CHO',
      'CHP',
      'CHR',
      'CHT',
      'CHU',
      'CHW',
      'CHX',
      'CHY',
      'CIL',
      'CIM',
      'CIR',
      'CIT',
      'CKH',
      'CKL',
      'CKN',
      'CKS',
      'CKT',
      'CLA',
      'CLC',
      'CLD',
      'CLE',
      'CLG',
      'CLH',
      'CLI',
      'CLJ',
      'CLK',
      'CLL',
      'CLM',
      'CLN',
      'CLP',
      'CLR',
      'CLS',
      'CLU',
      'CLV',
      'CLW',
      'CLY',
      'CMD',
      'CME',
      'CMF',
      'CMH',
      'CML',
      'CMN',
      'CMO',
      'CMR',
      'CMY',
      'CNE',
      'CNF',
      'CNG',
      'CNL',
      'CNM',
      'CNN',
      'CNO',
      'CNP',
      'CNR',
      'CNS',
      'CNW',
      'CNY',
      'COA',
      'COB',
      'COH',
      'COI',
      'COL',
      'COM',
      'CON',
      'COO',
      'COP',
      'COS',
      'COT',
      'COV',
      'COW',
      'COY',
      'CPA',
      'CPH',
      'CPK',
      'CPM',
      'CPN',
      'CPT',
      'CPU',
      'CPW',
      'CPY',
      'CRA',
      'CRB',
      'CRD',
      'CRE',
      'CRF',
      'CRG',
      'CRH',
      'CRI',
      'CRK',
      'CRM',
      'CRN',
      'CRR',
      'CRS',
      'CRT',
      'CRV',
      'CRW',
      'CRY',
      'CSA',
      'CSB',
      'CSD',
      'CSG',
      'CSH',
      'CSK',
      'CSL',
      'CSM',
      'CSN',
      'CSO',
      'CSR',
      'CSS',
      'CST',
      'CSW',
      'CSY',
      'CTF',
      'CTH',
      'CTK',
      'CTL',
      'CTM',
      'CTN',
      'CTO',
      'CTR',
      'CTT',
      'CTW',
      'CUA',
      'CUB',
      'CUD',
      'CUF',
      'CUH',
      'CUM',
      'CUP',
      'CUS',
      'CUW',
      'CUX',
      'CWB',
      'CWC',
      'CWE',
      'CWH',
      'CWL',
      'CWM',
      'CWN',
      'CWS',
      'CWU',
      'CYB',
      'CYK',
      'CYP',
      'CYS',
      'CYT',
      'DAG',
      'DAK',
      'DAL',
      'DAM',
      'DAN',
      'DAR',
      'DAT',
      'DBC',
      'DBD',
      'DBE',
      'DBG',
      'DBL',
      'DBR',
      'DBY',
      'DCG',
      'DCH',
      'DCT',
      'DCW',
      'DDG',
      'DDK',
      'DDP',
      'DEA',
      'DEE',
      'DEN',
      'DEP',
      'DEW',
      'DFD',
      'DFI',
      'DFR',
      'DGC',
      'DGL',
      'DGT',
      'DGY',
      'DHM',
      'DHN',
      'DID',
      'DIG',
      'DIN',
      'DIS',
      'DKD',
      'DKG',
      'DKT',
      'DLG',
      'DLH',
      'DLJ',
      'DLK',
      'DLM',
      'DLR',
      'DLS',
      'DLT',
      'DLW',
      'DLY',
      'DMC',
      'DMF',
      'DMH',
      'DMK',
      'DMP',
      'DMR',
      'DMS',
      'DMY',
      'DND',
      'DNG',
      'DNL',
      'DNM',
      'DNO',
      'DNS',
      'DNT',
      'DNY',
      'DOC',
      'DOD',
      'DOL',
      'DON',
      'DOR',
      'DOT',
      'DOW',
      'DPD',
      'DPT',
      'DRF',
      'DRG',
      'DRI',
      'DRM',
      'DRN',
      'DRO',
      'DRT',
      'DRU',
      'DSL',
      'DSM',
      'DST',
      'DSY',
      'DTG',
      'DTN',
      'DTW',
      'DUD',
      'DUL',
      'DUM',
      'DUN',
      'DUR',
      'DVC',
      'DVH',
      'DVN',
      'DVP',
      'DVY',
      'DWD',
      'DWL',
      'DWN',
      'DWW',
      'DYC',
      'DYF',
      'DYP',
      'DZY',
      'EAD',
      'EAG',
      'EAL',
      'EAR',
      'EAS',
      'EBA',
      'EBK',
      'EBL',
      'EBN',
      'EBR',
      'EBT',
      'ECC',
      'ECL',
      'ECR',
      'ECS',
      'ECW',
      'EDB',
      'EDG',
      'EDL',
      'EDN',
      'EDR',
      'EDW',
      'EDY',
      'EFF',
      'EFL',
      'EGF',
      'EGG',
      'EGH',
      'EGR',
      'EGT',
      'EKL',
      'ELD',
      'ELE',
      'ELG',
      'ELO',
      'ELP',
      'ELR',
      'ELS',
      'ELW',
      'ELY',
      'EMD',
      'EML',
      'EMP',
      'EMS',
      'ENC',
      'ENF',
      'ENL',
      'ENT',
      'EPD',
      'EPH',
      'EPS',
      'ERA',
      'ERD',
      'ERH',
      'ERI',
      'ERL',
      'ESD',
      'ESH',
      'ESL',
      'ESM',
      'EST',
      'ESW',
      'ETC',
      'ETL',
      'EUS',
      'EVE',
      'EWD',
      'EWE',
      'EWR',
      'EWW',
      'EXC',
      'EXD',
      'EXM',
      'EXN',
      'EXR',
      'EXT',
      'EYN',
      'FAL',
      'FAV',
      'FAZ',
      'FBY',
      'FCN',
      'FEA',
      'FEL',
      'FEN',
      'FER',
      'FFA',
      'FFD',
      'FGH',
      'FGT',
      'FIL',
      'FIN',
      'FIT',
      'FKC',
      'FKK',
      'FKW',
      'FLD',
      'FLE',
      'FLF',
      'FLI',
      'FLM',
      'FLN',
      'FLT',
      'FLW',
      'FLX',
      'FML',
      'FMR',
      'FMT',
      'FNB',
      'FNC',
      'FNH',
      'FNN',
      'FNR',
      'FNT',
      'FNV',
      'FNW',
      'FNY',
      'FOC',
      'FOD',
      'FOG',
      'FOH',
      'FOK',
      'FOR',
      'FOX',
      'FPK',
      'FRB',
      'FRD',
      'FRE',
      'FRF',
      'FRL',
      'FRM',
      'FRN',
      'FRO',
      'FRS',
      'FRT',
      'FRW',
      'FRY',
      'FSB',
      'FSG',
      'FSK',
      'FST',
      'FTM',
      'FTN',
      'FTW',
      'FWY',
      'FXN',
      'FYS',
      'FZH',
      'FZP',
      'FZW',
      'GAL',
      'GAR',
      'GBK',
      'GBL',
      'GBS',
      'GCH',
      'GCR',
      'GCT',
      'GCW',
      'GDH',
      'GDL',
      'GDN',
      'GDP',
      'GEA',
      'GER',
      'GFD',
      'GFF',
      'GFN',
      'GGJ',
      'GGV',
      'GIL',
      'GIP',
      'GIR',
      'GKC',
      'GKW',
      'GLC',
      'GLD',
      'GLE',
      'GLF',
      'GLG',
      'GLM',
      'GLO',
      'GLQ',
      'GLS',
      'GLT',
      'GLY',
      'GLZ',
      'GMB',
      'GMD',
      'GMG',
      'GMN',
      'GMT',
      'GMV',
      'GMY',
      'GNB',
      'GNF',
      'GNH',
      'GNL',
      'GNR',
      'GNT',
      'GNW',
      'GOB',
      'GOD',
      'GOE',
      'GOL',
      'GOM',
      'GOO',
      'GOR',
      'GOS',
      'GOX',
      'GPK',
      'GPO',
      'GRA',
      'GRB',
      'GRC',
      'GRF',
      'GRK',
      'GRL',
      'GRN',
      'GRP',
      'GRS',
      'GRT',
      'GRV',
      'GRY',
      'GSD',
      'GSL',
      'GSN',
      'GSW',
      'GSY',
      'GTA',
      'GTH',
      'GTN',
      'GTO',
      'GTR',
      'GTW',
      'GUI',
      'GUN',
      'GVH',
      'GWE',
      'GWN',
      'GYM',
      'GYP',
      'HAB',
      'HAC',
      'HAD',
      'HAG',
      'HAI',
      'HAL',
      'HAM',
      'HAN',
      'HAP',
      'HAS',
      'HAT',
      'HAV',
      'HAY',
      'HAZ',
      'HBB',
      'HBD',
      'HBN',
      'HBP',
      'HBY',
      'HCB',
      'HCH',
      'HCN',
      'HCT',
      'HDB',
      'HDE',
      'HDF',
      'HDG',
      'HDH',
      'HDL',
      'HDM',
      'HDN',
      'HDW',
      'HDY',
      'HEC',
      'HED',
      'HEI',
      'HEL',
      'HEN',
      'HER',
      'HES',
      'HEV',
      'HEW',
      'HEX',
      'HFD',
      'HFE',
      'HFN',
      'HFS',
      'HFX',
      'HGD',
      'HGF',
      'HGG',
      'HGM',
      'HGN',
      'HGR',
      'HGS',
      'HGT',
      'HGY',
      'HHB',
      'HHD',
      'HHE',
      'HHL',
      'HHY',
      'HIA',
      'HIB',
      'HID',
      'HIG',
      'HIL',
      'HIN',
      'HIP',
      'HIR',
      'HIT',
      'HKC',
      'HKH',
      'HKM',
      'HKN',
      'HKW',
      'HLB',
      'HLC',
      'HLD',
      'HLE',
      'HLF',
      'HLG',
      'HLI',
      'HLL',
      'HLM',
      'HLN',
      'HLR',
      'HLS',
      'HLU',
      'HLW',
      'HLY',
      'HMC',
      'HMD',
      'HME',
      'HML',
      'HMM',
      'HMN',
      'HMP',
      'HMS',
      'HMT',
      'HMW',
      'HMY',
      'HNA',
      'HNB',
      'HNC',
      'HND',
      'HNF',
      'HNG',
      'HNH',
      'HNK',
      'HNL',
      'HNT',
      'HNW',
      'HNX',
      'HOC',
      'HOH',
      'HOK',
      'HOL',
      'HON',
      'HOO',
      'HOP',
      'HOR',
      'HOT',
      'HOU',
      'HOV',
      'HOW',
      'HOX',
      'HOY',
      'HOZ',
      'HPA',
      'HPD',
      'HPE',
      'HPL',
      'HPN',
      'HPQ',
      'HPT',
      'HRD',
      'HRH',
      'HRL',
      'HRM',
      'HRN',
      'HRO',
      'HRR',
      'HRS',
      'HRW',
      'HRY',
      'HSB',
      'HSC',
      'HSD',
      'HSG',
      'HSK',
      'HSL',
      'HST',
      'HSW',
      'HSY',
      'HTC',
      'HTE',
      'HTF',
      'HTH',
      'HTN',
      'HTO',
      'HTW',
      'HTY',
      'HUB',
      'HUD',
      'HUL',
      'HUN',
      'HUP',
      'HUR',
      'HUT',
      'HUY',
      'HVF',
      'HVN',
      'HWB',
      'HWC',
      'HWD',
      'HWI',
      'HWK',
      'HWM',
      'HWN',
      'HWW',
      'HWY',
      'HXM',
      'HYB',
      'HYC',
      'HYD',
      'HYH',
      'HYK',
      'HYL',
      'HYM',
      'HYN',
      'HYR',
      'HYS',
      'HYT',
      'HYW',
      'IFD',
      'IFI',
      'IGD',
      'ILK',
      'INC',
      'INE',
      'ING',
      'INH',
      'INK',
      'INP',
      'INR',
      'INS',
      'INT',
      'INV',
      'IPS',
      'IRL',
      'IRV',
      'ISL',
      'ISP',
      'IVR',
      'IVY',
      'JEQ',
      'JHN',
      'JOH',
      'JOR',
      'KBC',
      'KBF',
      'KBK',
      'KBN',
      'KBW',
      'KBX',
      'KCK',
      'KDB',
      'KDG',
      'KDY',
      'KEH',
      'KEI',
      'KEL',
      'KEM',
      'KEN',
      'KET',
      'KEY',
      'KGH',
      'KGL',
      'KGM',
      'KGN',
      'KGP',
      'KGS',
      'KGT',
      'KGX',
      'KID',
      'KIL',
      'KIR',
      'KIT',
      'KIV',
      'KKB',
      'KKD',
      'KKH',
      'KKM',
      'KKN',
      'KLD',
      'KLM',
      'KLN',
      'KLY',
      'KMH',
      'KMK',
      'KML',
      'KMP',
      'KMS',
      'KNA',
      'KND',
      'KNE',
      'KNF',
      'KNG',
      'KNI',
      'KNL',
      'KNN',
      'KNO',
      'KNR',
      'KNS',
      'KNT',
      'KNU',
      'KPA',
      'KPT',
      'KRK',
      'KSL',
      'KSN',
      'KSW',
      'KTH',
      'KTL',
      'KTN',
      'KTW',
      'KVP',
      'KWB',
      'KWD',
      'KWG',
      'KWL',
      'KWN',
      'KYL',
      'KYN',
      'LAC',
      'LAD',
      'LAI',
      'LAK',
      'LAM',
      'LAN',
      'LAP',
      'LAR',
      'LAS',
      'LAW',
      'LBG',
      'LBK',
      'LBO',
      'LBR',
      'LBT',
      'LBZ',
      'LCC',
      'LCG',
      'LCL',
      'LCN',
      'LCS',
      'LDN',
      'LDS',
      'LDY',
      'LEA',
      'LED',
      'LEE',
      'LEH',
      'LEI',
      'LEL',
      'LEM',
      'LEN',
      'LEO',
      'LER',
      'LES',
      'LET',
      'LEU',
      'LEW',
      'LEY',
      'LFD',
      'LGB',
      'LGD',
      'LGE',
      'LGF',
      'LGG',
      'LGJ',
      'LGK',
      'LGM',
      'LGN',
      'LGO',
      'LGS',
      'LGW',
      'LHA',
      'LHD',
      'LHE',
      'LHL',
      'LHM',
      'LHO',
      'LHS',
      'LHW',
      'LIC',
      'LID',
      'LIH',
      'LIN',
      'LIP',
      'LIS',
      'LIT',
      'LIV',
      'LKE',
      'LLA',
      'LLC',
      'LLD',
      'LLE',
      'LLF',
      'LLG',
      'LLH',
      'LLI',
      'LLJ',
      'LLL',
      'LLM',
      'LLN',
      'LLO',
      'LLS',
      'LLT',
      'LLV',
      'LLW',
      'LLY',
      'LMR',
      'LMS',
      'LNB',
      'LND',
      'LNG',
      'LNK',
      'LNR',
      'LNW',
      'LNY',
      'LNZ',
      'LOB',
      'LOC',
      'LOF',
      'LOH',
      'LOO',
      'LOS',
      'LOT',
      'LOW',
      'LPG',
      'LPR',
      'LPT',
      'LPW',
      'LPY',
      'LRB',
      'LRD',
      'LRG',
      'LSK',
      'LSN',
      'LST',
      'LSW',
      'LSY',
      'LTG',
      'LTK',
      'LTL',
      'LTM',
      'LTN',
      'LTP',
      'LTS',
      'LTT',
      'LTV',
      'LUD',
      'LUT',
      'LUX',
      'LVC',
      'LVG',
      'LVJ',
      'LVM',
      'LVN',
      'LVT',
      'LWH',
      'LWR',
      'LWS',
      'LWT',
      'LYC',
      'LYD',
      'LYE',
      'LYM',
      'LYP',
      'LYT',
      'LZB',
      'MAC',
      'MAG',
      'MAI',
      'MAL',
      'MAN',
      'MAO',
      'MAR',
      'MAS',
      'MAT',
      'MAU',
      'MAX',
      'MAY',
      'MBK',
      'MBR',
      'MCB',
      'MCE',
      'MCH',
      'MCM',
      'MCN',
      'MCO',
      'MCV',
      'MDB',
      'MDE',
      'MDG',
      'MDL',
      'MDN',
      'MDS',
      'MDW',
      'MEC',
      'MEL',
      'MEN',
      'MEO',
      'MEP',
      'MER',
      'MES',
      'MEV',
      'MEW',
      'MEX',
      'MFA',
      'MFF',
      'MFH',
      'MFL',
      'MFT',
      'MGM',
      'MGN',
      'MHM',
      'MHR',
      'MHS',
      'MIA',
      'MIC',
      'MIH',
      'MIJ',
      'MIK',
      'MIL',
      'MIM',
      'MIN',
      'MIR',
      'MIS',
      'MKC',
      'MKM',
      'MKR',
      'MKT',
      'MLB',
      'MLD',
      'MLF',
      'MLG',
      'MLH',
      'MLM',
      'MLS',
      'MLT',
      'MLW',
      'MLY',
      'MMO',
      'MNC',
      'MNE',
      'MNG',
      'MNN',
      'MNP',
      'MNR',
      'MOB',
      'MOG',
      'MON',
      'MOO',
      'MOR',
      'MOS',
      'MOT',
      'MPK',
      'MPL',
      'MPT',
      'MRB',
      'MRD',
      'MRF',
      'MRN',
      'MRP',
      'MRR',
      'MRS',
      'MRT',
      'MRY',
      'MSD',
      'MSH',
      'MSK',
      'MSL',
      'MSN',
      'MSO',
      'MSR',
      'MSS',
      'MST',
      'MSW',
      'MTA',
      'MTB',
      'MTC',
      'MTG',
      'MTH',
      'MTL',
      'MTM',
      'MTN',
      'MTO',
      'MTP',
      'MTS',
      'MTV',
      'MUB',
      'MUF',
      'MUI',
      'MVL',
      'MYB',
      'MYH',
      'MYL',
      'MYT',
      'MZH',
      'NAN',
      'NAR',
      'NAY',
      'NBA',
      'NBC',
      'NBN',
      'NBR',
      'NBT',
      'NBW',
      'NBY',
      'NCE',
      'NCK',
      'NCL',
      'NCM',
      'NCT',
      'NDL',
      'NEH',
      'NEI',
      'NEL',
      'NEM',
      'NES',
      'NET',
      'NFD',
      'NFL',
      'NFN',
      'NGT',
      'NHD',
      'NHE',
      'NHL',
      'NIT',
      'NLN',
      'NLR',
      'NLS',
      'NLT',
      'NLW',
      'NMC',
      'NMK',
      'NMN',
      'NMP',
      'NMT',
      'NNG',
      'NNP',
      'NNT',
      'NOA',
      'NOR',
      'NOT',
      'NPD',
      'NQU',
      'NQY',
      'NRB',
      'NRC',
      'NRD',
      'NRN',
      'NRT',
      'NRW',
      'NSB',
      'NSD',
      'NSG',
      'NSH',
      'NTA',
      'NTB',
      'NTC',
      'NTH',
      'NTL',
      'NTN',
      'NTR',
      'NUF',
      'NUM',
      'NUN',
      'NUT',
      'NVH',
      'NVN',
      'NVR',
      'NWA',
      'NWB',
      'NWD',
      'NWE',
      'NWI',
      'NWM',
      'NWN',
      'NWP',
      'NWR',
      'NWT',
      'NWX',
      'NXG',
      'OBN',
      'OCK',
      'OHL',
      'OKE',
      'OKL',
      'OKM',
      'OKN',
      'OLD',
      'OLF',
      'OLT',
      'OLY',
      'OMS',
      'OPK',
      'ORE',
      'ORN',
      'ORP',
      'ORR',
      'OTF',
      'OUN',
      'OUS',
      'OVE',
      'OVR',
      'OXF',
      'OXN',
      'OXS',
      'OXT',
      'PAD',
      'PAL',
      'PAN',
      'PAR',
      'PAT',
      'PBL',
      'PBO',
      'PBR',
      'PBY',
      'PCD',
      'PCN',
      'PDG',
      'PDW',
      'PEA',
      'PEB',
      'PEG',
      'PEM',
      'PEN',
      'PER',
      'PES',
      'PET',
      'PEV',
      'PEW',
      'PFL',
      'PFM',
      'PFR',
      'PFY',
      'PGM',
      'PGN',
      'PHG',
      'PHR',
      'PIL',
      'PIN',
      'PIT',
      'PKG',
      'PKS',
      'PKT',
      'PLC',
      'PLD',
      'PLE',
      'PLG',
      'PLK',
      'PLM',
      'PLN',
      'PLS',
      'PLT',
      'PLU',
      'PLW',
      'PLY',
      'PMA',
      'PMB',
      'PMD',
      'PMH',
      'PMP',
      'PMR',
      'PMS',
      'PMT',
      'PMW',
      'PNA',
      'PNC',
      'PNE',
      'PNF',
      'PNL',
      'PNM',
      'PNR',
      'PNS',
      'PNW',
      'PNY',
      'PNZ',
      'POK',
      'POL',
      'PON',
      'POO',
      'POP',
      'POR',
      'POT',
      'PPD',
      'PPK',
      'PPL',
      'PRA',
      'PRB',
      'PRE',
      'PRH',
      'PRL',
      'PRN',
      'PRP',
      'PRR',
      'PRS',
      'PRT',
      'PRU',
      'PRW',
      'PRY',
      'PSC',
      'PSE',
      'PSH',
      'PSL',
      'PSN',
      'PST',
      'PSW',
      'PTA',
      'PTB',
      'PTC',
      'PTD',
      'PTF',
      'PTG',
      'PTH',
      'PTK',
      'PTL',
      'PTM',
      'PTR',
      'PTT',
      'PTW',
      'PUL',
      'PUO',
      'PUR',
      'PUT',
      'PWE',
      'PWL',
      'PWW',
      'PWY',
      'PYC',
      'PYG',
      'PYJ',
      'PYL',
      'PYN',
      'PYP',
      'PYT',
      'QBR',
      'QPK',
      'QPW',
      'QRB',
      'QRP',
      'QUI',
      'QYD',
      'RAD',
      'RAI',
      'RAM',
      'RAN',
      'RAU',
      'RAV',
      'RAY',
      'RBR',
      'RCC',
      'RCD',
      'RCE',
      'RDA',
      'RDB',
      'RDC',
      'RDD',
      'RDF',
      'RDG',
      'RDH',
      'RDM',
      'RDN',
      'RDR',
      'RDS',
      'RDT',
      'RDW',
      'REC',
      'RED',
      'REE',
      'REI',
      'RET',
      'RFD',
      'RFY',
      'RGL',
      'RGT',
      'RGW',
      'RHI',
      'RHL',
      'RHM',
      'RHO',
      'RHY',
      'RIC',
      'RID',
      'RIL',
      'RIS',
      'RKT',
      'RLG',
      'RLN',
      'RMB',
      'RMC',
      'RMD',
      'RMF',
      'RML',
      'RNF',
      'RNH',
      'RNM',
      'RNR',
      'ROB',
      'ROC',
      'ROE',
      'ROG',
      'ROL',
      'ROM',
      'ROO',
      'ROS',
      'ROW',
      'RRB',
      'RSG',
      'RSH',
      'RTN',
      'RTR',
      'RUA',
      'RUE',
      'RUF',
      'RUG',
      'RUN',
      'RUS',
      'RUT',
      'RVB',
      'RVN',
      'RWC',
      'RYB',
      'RYD',
      'RYE',
      'RYH',
      'RYN',
      'RYP',
      'RYR',
      'RYS',
      'SAA',
      'SAB',
      'SAC',
      'SAD',
      'SAE',
      'SAF',
      'SAH',
      'SAJ',
      'SAL',
      'SAM',
      'SAN',
      'SAR',
      'SAS',
      'SAT',
      'SAU',
      'SAV',
      'SAW',
      'SAX',
      'SAY',
      'SBE',
      'SBF',
      'SBJ',
      'SBK',
      'SBM',
      'SBP',
      'SBR',
      'SBS',
      'SBT',
      'SBU',
      'SBV',
      'SBY',
      'SCA',
      'SCF',
      'SCG',
      'SCH',
      'SCR',
      'SCS',
      'SCT',
      'SCU',
      'SCY',
      'SDA',
      'SDB',
      'SDE',
      'SDF',
      'SDG',
      'SDH',
      'SDL',
      'SDM',
      'SDN',
      'SDP',
      'SDR',
      'SDW',
      'SDY',
      'SEA',
      'SEB',
      'SEC',
      'SED',
      'SEE',
      'SEF',
      'SEG',
      'SEH',
      'SEL',
      'SEM',
      'SEN',
      'SER',
      'SES',
      'SET',
      'SEV',
      'SFA',
      'SFD',
      'SFL',
      'SFN',
      'SFO',
      'SFR',
      'SGB',
      'SGL',
      'SGM',
      'SGN',
      'SGR',
      'SHC',
      'SHD',
      'SHE',
      'SHF',
      'SHH',
      'SHI',
      'SHJ',
      'SHL',
      'SHM',
      'SHN',
      'SHO',
      'SHP',
      'SHR',
      'SHS',
      'SHT',
      'SHU',
      'SHW',
      'SHY',
      'SIA',
      'SIC',
      'SID',
      'SIE',
      'SIH',
      'SIL',
      'SIN',
      'SIP',
      'SIT',
      'SIV',
      'SJP',
      'SJS',
      'SKE',
      'SKG',
      'SKI',
      'SKM',
      'SKN',
      'SKS',
      'SKW',
      'SLA',
      'SLB',
      'SLD',
      'SLH',
      'SLK',
      'SLL',
      'SLO',
      'SLQ',
      'SLR',
      'SLS',
      'SLT',
      'SLV',
      'SLW',
      'SLY',
      'SMA',
      'SMB',
      'SMD',
      'SMG',
      'SMH',
      'SMK',
      'SML',
      'SMN',
      'SMO',
      'SMR',
      'SMT',
      'SMY',
      'SNA',
      'SND',
      'SNE',
      'SNF',
      'SNG',
      'SNH',
      'SNK',
      'SNL',
      'SNN',
      'SNO',
      'SNR',
      'SNS',
      'SNT',
      'SNW',
      'SNY',
      'SOA',
      'SOB',
      'SOC',
      'SOE',
      'SOG',
      'SOH',
      'SOK',
      'SOL',
      'SOM',
      'SON',
      'SOO',
      'SOP',
      'SOR',
      'SOT',
      'SOU',
      'SOV',
      'SOW',
      'SPA',
      'SPB',
      'SPF',
      'SPH',
      'SPI',
      'SPK',
      'SPN',
      'SPO',
      'SPP',
      'SPR',
      'SPS',
      'SPT',
      'SPU',
      'SQE',
      'SQH',
      'SQU',
      'SRA',
      'SRC',
      'SRD',
      'SRG',
      'SRH',
      'SRI',
      'SRL',
      'SRN',
      'SRO',
      'SRR',
      'SRS',
      'SRT',
      'SRU',
      'SRY',
      'SSC',
      'SSD',
      'SSE',
      'SSM',
      'SSS',
      'SST',
      'STA',
      'STC',
      'STD',
      'STE',
      'STF',
      'STG',
      'STH',
      'STJ',
      'STK',
      'STL',
      'STM',
      'STN',
      'STO',
      'STP',
      'STR',
      'STS',
      'STT',
      'STU',
      'STV',
      'STW',
      'SUC',
      'SUD',
      'SUG',
      'SUM',
      'SUN',
      'SUO',
      'SUP',
      'SUR',
      'SUT',
      'SUU',
      'SUY',
      'SVB',
      'SVG',
      'SVK',
      'SVL',
      'SVR',
      'SVS',
      'SWA',
      'SWD',
      'SWE',
      'SWG',
      'SWI',
      'SWK',
      'SWL',
      'SWM',
      'SWN',
      'SWO',
      'SWR',
      'SWS',
      'SWT',
      'SWY',
      'SXY',
      'SYA',
      'SYB',
      'SYD',
      'SYH',
      'SYL',
      'SYS',
      'SYT',
      'TAB',
      'TAC',
      'TAD',
      'TAF',
      'TAI',
      'TAL',
      'TAM',
      'TAP',
      'TAT',
      'TAU',
      'TAY',
      'TBD',
      'TBR',
      'TBW',
      'TBY',
      'TDU',
      'TEA',
      'TED',
      'TEN',
      'TEO',
      'TEY',
      'TFC',
      'TGM',
      'TGS',
      'THA',
      'THB',
      'THC',
      'THD',
      'THE',
      'THH',
      'THI',
      'THL',
      'THO',
      'THS',
      'THT',
      'THU',
      'THW',
      'TIL',
      'TIP',
      'TIR',
      'TIS',
      'TLB',
      'TLC',
      'TLH',
      'TLK',
      'TLS',
      'TMC',
      'TNA',
      'TNF',
      'TNN',
      'TNP',
      'TNS',
      'TOD',
      'TOK',
      'TOL',
      'TOM',
      'TON',
      'TOO',
      'TOP',
      'TOT',
      'TPB',
      'TPC',
      'TPN',
      'TQY',
      'TRA',
      'TRB',
      'TRD',
      'TRE',
      'TRF',
      'TRH',
      'TRI',
      'TRM',
      'TRN',
      'TRO',
      'TRR',
      'TRS',
      'TRU',
      'TRY',
      'TTF',
      'TTH',
      'TTN',
      'TUH',
      'TUL',
      'TUR',
      'TUT',
      'TVP',
      'TWI',
      'TWN',
      'TWY',
      'TYC',
      'TYG',
      'TYL',
      'TYS',
      'TYW',
      'UCK',
      'UDD',
      'UHA',
      'UHL',
      'ULC',
      'ULL',
      'ULV',
      'UMB',
      'UNI',
      'UPH',
      'UPL',
      'UPM',
      'UPT',
      'UPW',
      'URM',
      'UTT',
      'UTY',
      'UWL',
      'VAL',
      'VIC',
      'VIR',
      'VXH',
      'WAC',
      'WAD',
      'WAE',
      'WAF',
      'WAL',
      'WAM',
      'WAN',
      'WAO',
      'WAR',
      'WAS',
      'WAT',
      'WAW',
      'WBC',
      'WBD',
      'WBL',
      'WBO',
      'WBP',
      'WBQ',
      'WBR',
      'WBY',
      'WCB',
      'WCH',
      'WCK',
      'WCL',
      'WCM',
      'WCP',
      'WCR',
      'WCX',
      'WCY',
      'WDB',
      'WDD',
      'WDE',
      'WDH',
      'WDL',
      'WDM',
      'WDN',
      'WDO',
      'WDS',
      'WDT',
      'WDU',
      'WEA',
      'WED',
      'WEE',
      'WEH',
      'WEL',
      'WEM',
      'WES',
      'WET',
      'WEY',
      'WFF',
      'WFH',
      'WFI',
      'WFJ',
      'WFL',
      'WFN',
      'WGA',
      'WGC',
      'WGN',
      'WGR',
      'WGT',
      'WGV',
      'WGW',
      'WHA',
      'WHC',
      'WHD',
      'WHE',
      'WHG',
      'WHI',
      'WHL',
      'WHM',
      'WHN',
      'WHP',
      'WHR',
      'WHS',
      'WHT',
      'WHY',
      'WIC',
      'WID',
      'WIH',
      'WIJ',
      'WIL',
      'WIM',
      'WIN',
      'WIV',
      'WKB',
      'WKD',
      'WKF',
      'WKG',
      'WKI',
      'WKK',
      'WKM',
      'WLC',
      'WLD',
      'WLE',
      'WLF',
      'WLG',
      'WLI',
      'WLM',
      'WLN',
      'WLO',
      'WLP',
      'WLS',
      'WLT',
      'WLV',
      'WLW',
      'WLY',
      'WMA',
      'WMB',
      'WMC',
      'WMD',
      'WME',
      'WMG',
      'WMI',
      'WML',
      'WMN',
      'WMR',
      'WMS',
      'WMW',
      'WNC',
      'WND',
      'WNE',
      'WNF',
      'WNG',
      'WNH',
      'WNL',
      'WNM',
      'WNN',
      'WNP',
      'WNR',
      'WNS',
      'WNT',
      'WNW',
      'WNY',
      'WOB',
      'WOF',
      'WOH',
      'WOK',
      'WOL',
      'WOM',
      'WON',
      'WOO',
      'WOR',
      'WOS',
      'WPE',
      'WPL',
      'WRB',
      'WRE',
      'WRH',
      'WRK',
      'WRL',
      'WRM',
      'WRN',
      'WRP',
      'WRS',
      'WRT',
      'WRU',
      'WRW',
      'WRX',
      'WRY',
      'WSA',
      'WSB',
      'WSE',
      'WSF',
      'WSH',
      'WSL',
      'WSM',
      'WSR',
      'WST',
      'WSU',
      'WSW',
      'WTA',
      'WTB',
      'WTC',
      'WTE',
      'WTG',
      'WTH',
      'WTI',
      'WTL',
      'WTM',
      'WTN',
      'WTO',
      'WTR',
      'WTS',
      'WTT',
      'WTY',
      'WVF',
      'WVH',
      'WWA',
      'WWD',
      'WWI',
      'WWO',
      'WWR',
      'WWW',
      'WXC',
      'WYB',
      'WYE',
      'WYL',
      'WYM',
      'WYT',
      'YAE',
      'YAL',
      'YAT',
      'YEO',
      'YET',
      'YMH',
      'YNW',
      'YOK',
      'YRD',
      'YRK',
      'YRM',
      'YRT',
      'YSM',
      'YSR',
      'YVJ',
      'YVP',
      'ZCW',
      'ZFD',
      'ZLW',
    ]
  }

  get ADDITIONAL_STATIONS() {
    return [
      {
        title: 'Ampfield (AMD)',
        value: 'AMD',
      },
      {
        title: 'Amsterdam Centraal (AMS)',
        value: 'AMS',
      },
      {
        title: 'Brodick (Bus) (BDC)',
        value: 'BDC',
      },
      {
        title: 'Bordon Camp (Bus) (BDZ)',
        value: 'BDZ',
      },
      {
        title: 'Benton (Tyne & Wear Metro) (BNO)',
        value: 'BNO',
      },
      {
        title: 'Brussels (Bruxelles) (BXS)',
        value: 'BXS',
      },
      {
        title: 'Craignure, Mull (Bus) (CRU)',
        value: 'CRU',
      },
      {
        title: 'Castlebay, Barra (Bus) (CTB)',
        value: 'CTB',
      },
      {
        title: 'Colonsay (Bus) (CYA)',
        value: 'CYA',
      },
      {
        title: 'Douglas, Isle of Man (DGS)',
        value: 'DGS',
      },
      {
        title: 'Derker, Metrolink (DKR)',
        value: 'DKR',
      },
      {
        title: 'Dean Lane, Metrolink (DNN)',
        value: 'DNN',
      },
      {
        title: 'Edinburgh Bus Station (EBS)',
        value: 'EBS',
      },
      {
        title: 'Folkestone Harbour (Bus) (FKH)',
        value: 'FKH',
      },
      {
        title: 'Failsworth, Metrolink (FLS)',
        value: 'FLS',
      },
      {
        title: 'Groombridge (Bus) (GRO)',
        value: 'GRO',
      },
      {
        title: 'Hursley (Post Office) (Bus) (HSE)',
        value: 'HSE',
      },
      {
        title: 'Hunstanton (Bus) (HUS)',
        value: 'HUS',
      },
      {
        title: 'Hythe Waterside (Bus) (HYZ)',
        value: 'HYZ',
      },
      {
        title: 'IBM (IBM)',
        value: 'IBM',
      },
      {
        title: 'Kilcreggan (Bus) (KCG)',
        value: 'KCG',
      },
      {
        title: 'Lochboisdale, South Uist (Bus) (LCB)',
        value: 'LCB',
      },
      {
        title: 'Lochmaddy, North Uist (Bus) (LCH)',
        value: 'LCH',
      },
      {
        title: 'Lindford (Bus) (LNF)',
        value: 'LNF',
      },
      {
        title: 'Marne-la-Vallée (MCK)',
        value: 'MCK',
      },
      {
        title: 'Milnrow, Metrolink (MLR)',
        value: 'MLR',
      },
      {
        title: 'Manors (MRM)',
        value: 'MRM',
      },
      {
        title: 'Muck (Bus) (MUK)',
        value: 'MUK',
      },
      {
        title: 'Newhey, Metrolink (NHY)',
        value: 'NHY',
      },
      {
        title: 'Norden, Swanage Railway (NOD)',
        value: 'NOD',
      },
      {
        title: 'Oldham Mumps, Metrolink (OLM)',
        value: 'OLM',
      },
      {
        title: 'Oldham Werneth (closed) (OLW)',
        value: 'OLW',
      },
      {
        title: 'Paris Nord (PBN)',
        value: 'PBN',
      },
      {
        title: 'Peebles Bus Stop (PBS)',
        value: 'PBS',
      },
      {
        title: 'Porthmadog Harbour (Bus) (PMG)',
        value: 'PMG',
      },
      {
        title: 'British Steel Redcar (closed) (RBS)',
        value: 'RBS',
      },
      {
        title: 'Rotterdam (ROT)',
        value: 'ROT',
      },
      {
        title: 'Rosslare Europort (RSB)',
        value: 'RSB',
      },
      {
        title: 'Rothesay, Bute (Bus) (RTY)',
        value: 'RTY',
      },
      {
        title: 'Scrabster (Bus) (SCB)',
        value: 'SCB',
      },
      {
        title: 'Swanage, Swanage Railway (SGE)',
        value: 'SGE',
      },
      {
        title: 'Stretford, Metrolink (SRF)',
        value: 'SRF',
      },
      {
        title: 'Stranraer West Pier (Bus) (SWP)',
        value: 'SWP',
      },
      {
        title: 'Tarbert, Harris (Bus) (TBT)',
        value: 'TBT',
      },
      {
        title: 'Tiree (Bus) (TEE)',
        value: 'TEE',
      },
      {
        title: 'Tobermory, Mull (Bus) (TOB)',
        value: 'TOB',
      },
      {
        title: 'Ullapool (Bus) (ULP)',
        value: 'ULP',
      },
      {
        title: 'Whitehill Prince of Wales (Bus) (WHH)',
        value: 'WHH',
      },
      {
        title: 'West Cowes (WTW)',
        value: 'WTW',
      },
      {
        title: 'Weymouth Quay (WYQ)',
        value: 'WYQ',
      },
      {
        title: 'Birmingham Airport (Bus) (XFG)',
        value: 'XFG',
      },
      {
        title: 'Barbican Underground (ZBB)',
        value: 'ZBB',
      },
    ]
  }

  protected get SHORT_PLATFORMS() {
    return [
      { value: 'front.1', title: 'Front coach' },
      { value: 'front.2', title: 'Front 2 coaches' },
      { value: 'front.3', title: 'Front 3 coaches' },
      { value: 'front.4', title: 'Front 4 coaches' },
      { value: 'front.5', title: 'Front 5 coaches' },
      { value: 'front.6', title: 'Front 6 coaches' },
      { value: 'front.7', title: 'Front 7 coaches' },
      { value: 'front.8', title: 'Front 8 coaches' },
      { value: 'front.9', title: 'Front 9 coaches' },
      { value: 'front.10', title: 'Front 10 coaches' },
      { value: 'front.11', title: 'Front 11 coaches' },
      { value: 'front.12', title: 'Front 12 coaches' },
      { value: 'middle.1', title: 'Middle coach' },
      { value: 'middle.2', title: 'Middle 2 coaches' },
      { value: 'middle.3', title: 'Middle 3 coaches' },
      { value: 'middle.4', title: 'Middle 4 coaches' },
      { value: 'middle.5', title: 'Middle 5 coaches' },
      { value: 'middle.6', title: 'Middle 6 coaches' },
      { value: 'middle.7', title: 'Middle 7 coaches' },
      { value: 'middle.8', title: 'Middle 8 coaches' },
      { value: 'middle.9', title: 'Middle 9 coaches' },
      { value: 'middle.10', title: 'Middle 10 coaches' },
      { value: 'middle.11', title: 'Middle 11 coaches' },
      { value: 'middle.12', title: 'Middle 12 coaches' },
      { value: 'rear.1', title: 'Rear coach' },
      { value: 'rear.2', title: 'Rear 2 coaches' },
      { value: 'rear.3', title: 'Rear 3 coaches' },
      { value: 'rear.4', title: 'Rear 4 coaches' },
      { value: 'rear.5', title: 'Rear 5 coaches' },
      { value: 'rear.6', title: 'Rear 6 coaches' },
      { value: 'rear.7', title: 'Rear 7 coaches' },
      { value: 'rear.8', title: 'Rear 8 coaches' },
      { value: 'rear.9', title: 'Rear 9 coaches' },
      { value: 'rear.10', title: 'Rear 10 coaches' },
      { value: 'rear.11', title: 'Rear 11 coaches' },
      { value: 'rear.12', title: 'Rear 12 coaches' },
    ]
  }

  protected get SPLITS() {
    return [
      { value: 'front.1', title: 'Front coach' },
      { value: 'front.2', title: 'Front 2 coaches' },
      { value: 'front.3', title: 'Front 3 coaches' },
      { value: 'front.4', title: 'Front 4 coaches' },
      { value: 'front.5', title: 'Front 5 coaches' },
      { value: 'front.6', title: 'Front 6 coaches' },
      { value: 'front.7', title: 'Front 7 coaches' },
      { value: 'front.8', title: 'Front 8 coaches' },
      { value: 'front.9', title: 'Front 9 coaches' },
      { value: 'front.10', title: 'Front 10 coaches' },
      { value: 'front.11', title: 'Front 11 coaches' },
      { value: 'front.12', title: 'Front 12 coaches' },
      // { value: 'middle.1', title: 'Middle coach' },
      // { value: 'middle.2', title: 'Middle 2 coaches' },
      // { value: 'middle.3', title: 'Middle 3 coaches' },
      // { value: 'middle.4', title: 'Middle 4 coaches' },
      // { value: 'middle.5', title: 'Middle 5 coaches' },
      // { value: 'middle.6', title: 'Middle 6 coaches' },
      // { value: 'middle.7', title: 'Middle 7 coaches' },
      // { value: 'middle.8', title: 'Middle 8 coaches' },
      // { value: 'middle.9', title: 'Middle 9 coaches' },
      // { value: 'middle.10', title: 'Middle 10 coaches' },
      // { value: 'middle.11', title: 'Middle 11 coaches' },
      // { value: 'middle.12', title: 'Middle 12 coaches' },
      { value: 'rear.1', title: 'Rear coach' },
      { value: 'rear.2', title: 'Rear 2 coaches' },
      { value: 'rear.3', title: 'Rear 3 coaches' },
      { value: 'rear.4', title: 'Rear 4 coaches' },
      { value: 'rear.5', title: 'Rear 5 coaches' },
      { value: 'rear.6', title: 'Rear 6 coaches' },
      { value: 'rear.7', title: 'Rear 7 coaches' },
      { value: 'rear.8', title: 'Rear 8 coaches' },
      { value: 'rear.9', title: 'Rear 9 coaches' },
      { value: 'rear.10', title: 'Rear 10 coaches' },
      { value: 'rear.11', title: 'Rear 11 coaches' },
      { value: 'rear.12', title: 'Rear 12 coaches' },
    ]
  }

  protected get announcementPresets() {
    const base = super.announcementPresets
    const newPresets = {} as Writeable<typeof base>

    // Ensure none exist with platforms not in the list
    newPresets.nextTrain = base.nextTrain
      .map(preset => {
        const platforms = [preset.state.platform, preset.state.oldPlatform, preset.state.newPlatform]

        if (platforms.some(p => p && !this.PLATFORMS.includes(p))) {
          return null
        }

        return preset
      })
      .filter(Boolean) as typeof base.nextTrain

    return newPresets
  }

  protected readonly requestStopOptions = {
    andId: 'm.or',
  }

  protected readonly splitOptions = {
    travelInCorrectPartId: ['s.please make sure you travel', 'e.in the correct part of this train'],
    travelInAnyPartIds: ['e.may travel in any part of the train-2'],
  }

  protected readonly disruptionOptions = {
    thisStationAudio: 'e.this station',
  }

  processTocForLiveTrains(
    tocName: string,
    tocCode: string,
    originCrs: string,
    destinationCrs: string,
    useLegacy: boolean,
    trainUid: string,
  ): string {
    if (useLegacy) {
      switch (tocCode.toUpperCase()) {
        case 'AW':
          return 'arriva trains wales'
        case 'CC':
          return 'c2c'
        case 'CH':
          return 'chiltern railways'
        case 'CS':
          return 'caledonian sleeper'
        case 'EM':
          return 'east midlands trains'
        case 'ES':
          return 'eurostar'
        case 'GC':
          return 'grand central'
        case 'GN':
          return 'first capital connect'
        case 'GR':
          return 'national express east coast'
        case 'GW':
          const namedMatch = Object.entries(NamedServices.GW.services)
            .find(([_, uids]) => uids.includes(trainUid))?.[0]
            ?.toLowerCase()

          if (namedMatch && this.ALL_AVAILABLE_TOCS.includes(`first great western ${namedMatch}`)) return `first great western ${namedMatch}`

          return 'first great western'
        case 'GX':
          return 'gatwick express'
        case 'HT':
          return 'hull trains'
        case 'HX':
          return 'heathrow express'
        case 'IL':
          return 'island line'
        // case 'LD':
        //   return ''
        case 'LE':
          // Typically didn't appear in announcements because 'one' presents confusion with times
          // return 'one'
          return ''
        case 'LM':
          return 'london midland'
        case 'LO':
          return 'london overground'
        // return 'silverlink metro'
        case 'ME':
          return 'merseyrail'
        case 'NT':
          return 'northern rail'
        // return 'arriva trains northern'
        case 'SE':
          return 'southeastern'
        case 'SN':
          return 'southern'
        case 'SR':
          return 'scotrail'
        case 'SW':
          return 'south west trains'
        case 'TL':
          return 'first capital connect'
        case 'TP':
          return 'first transpennine express'
        case 'TW':
          return 'tyne and wear metro'
        case 'VT':
          return 'virgin trains'
        case 'XC':
          return 'virgin trains'
        // case 'XR':
        //   return ''

        default:
          return this.ALL_AVAILABLE_TOCS.find(t => t?.toLowerCase() === tocName?.toLowerCase()) ?? ''
      }
    }

    switch (tocCode.toUpperCase()) {
      // LNER has different filename compared to TOC name
      case 'GR':
        return 'london north eastern railway'

      // Handle named GWR services
      case 'GW':
        const namedMatch = Object.entries(NamedServices.GW.services)
          .find(([_, uids]) => uids.includes(trainUid))?.[0]
          ?.toLowerCase()

        if (namedMatch && this.ALL_AVAILABLE_TOCS.includes(`first great western ${namedMatch}`)) return `first great western ${namedMatch}`

        return 'great western railway'

      // West Midlands Trains
      case 'LM':
        // https://www.westmidlandsrailway.co.uk/media/3657/download?inline
        const lnwr = ['EUS', 'CRE', 'BDM', 'SAA', 'MKC', 'TRI', 'LIV', 'NMP']
        if (lnwr.includes(originCrs) || lnwr.includes(destinationCrs)) {
          return 'london northwestern railway'
        } else {
          return 'west midlands railway'
        }

      default:
        return this.ALL_AVAILABLE_TOCS.find(t => t?.toLowerCase() === tocName?.toLowerCase()) ?? ''
    }
  }

  protected getAnnouncementButtons(): Record<string, CustomAnnouncementButton[]> {
    return {
      General: [
        {
          label: '3 chimes',
          play: this.playAudioFiles.bind(this, [this.getChime('three')!!]),
          download: this.playAudioFiles.bind(this, [this.getChime('three')!!], true),
        },
        {
          label: '4 chimes',
          play: this.playAudioFiles.bind(this, [this.getChime('four')!!]),
          download: this.playAudioFiles.bind(this, [this.getChime('four')!!], true),
        },
      ],
      Emergency: [
        {
          label: 'Superintendent to carriage depot',
          play: this.playAudioFiles.bind(this, ['w.would the superindendent of the line please go to the carriage depot']),
          download: this.playAudioFiles.bind(this, ['w.would the superindendent of the line please go to the carriage depot'], true),
        },
        {
          label: 'Mr Neptune to town centre',
          play: this.playAudioFiles.bind(this, ['w.would mr neptune please go to the town centre immediately']),
          download: this.playAudioFiles.bind(this, ['w.would mr neptune please go to the town centre immediately'], true),
        },
        {
          label: 'Please leave the station immediately',
          play: this.playAudioFiles.bind(this, ['w.please leave the station immediately']),
          download: this.playAudioFiles.bind(this, ['w.please leave the station immediately'], true),
        },
      ],
    }
  }
}
